import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import content from "./paintings.json";
import { Link } from "react-router-dom";
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useSpring,
} from "framer-motion";
import { Eye } from "../shared";
type PaintingType = {
  title: string;
  dimension: string;
  technique: string;
  available: boolean;
  source: string;
};

function Painting({
  title,
  dimension,
  technique,
  available,
  source,
}: PaintingType) {
  const [isVisible, setIsVisible] = useState(false);

  const x = useSpring((Math.random() > 0.5 ? 1 : -1) * Math.random() * 1000, {
    stiffness: 150,
    damping: 30,
  });
  const y = useSpring((Math.random() > 0.5 ? 1 : -1) * Math.random() * 1000, {
    stiffness: 150,
    damping: 30,
  });

  function handleMouse(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    var rect = event.currentTarget.getBoundingClientRect();
    x.set(event.clientX - rect.left);
    y.set(event.clientY - rect.top - rect.height);
  }

  return (
    <article className={style.painting}>
      <h3 className={style.title}>{title}</h3>
      <motion.div
        onMouseMove={handleMouse}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className={style.pictureContainer}
      >
        <motion.img
          animate={{
            opacity: isVisible ? 0.2 : 1,
          }}
          className={style.picture}
          src={source}
          alt={title}
        />
        <AnimatePresence>
          {isVisible && (
            <motion.div
              key="eye"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                x: x,
                y: y,
              }}
            >
              <Eye />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <p className={style.dimension}>{dimension}</p>
      <p className={style.technique}>{technique}</p>
      <p className={style.available}>{available ? "Disponible" : "Vendu"}</p>
    </article>
  );
}

export default function Galerie() {
  return (
    <>
      <motion.section className={style.galerie}>
        {content
          .map((p: PaintingType) => <Painting key={p.title} {...p}></Painting>)
          .map((component: any, i: number) => (
            <Link
              key={i}
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/painting/" + i}
            >
              {component}
            </Link>
          ))}
      </motion.section>
    </>
  );
}
