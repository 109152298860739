import React from "react";
import Reveal from "react-reveal/Reveal";

import style from "./style.module.css";
import Links from "../footer/links";
import { Link } from "react-router-dom";
import video from "./resources/jeunesse10.mp4";
import whatitis from "./resources/portraits10.mp4";
import cp1 from "./resources/cp1.png";
import cp2 from "./resources/cp2.png";
import cp3 from "./resources/cp3.png";
import cp4 from "./resources/cp4.png";
import itep1 from "./resources/itep1.png";
import itep2 from "./resources/itep2.png";
import itep3 from "./resources/itep3.png";
import itep4 from "./resources/itep4.png";
import segpa1 from "./resources/segpa1.png";
import segpa2 from "./resources/segpa2.png";
import segpa3 from "./resources/segpa3.png";
import segpa4 from "./resources/segpa4.png";
import mces1 from "./resources/mces1.png";
import mces2 from "./resources/mces2.png";
import mces3 from "./resources/mces3.png";
import mces4 from "./resources/mces4.png";
import p1 from "./resources/p1.png";
import p2 from "./resources/p2.png";
import p3 from "./resources/p3.png";
import p4 from "./resources/p4.png";
import Partners from "../partners";
import { Arrow } from "../shared";
export default function () {
  return (
    <section className={style.youth}>
      <article className={style.intro}>
        <Reveal effect="slidein">
          <p>L'Objectif</p>
          <h1>Faire émerger l'intelligence émotionnelle</h1>
        </Reveal>

        <iframe
          className={style.introVideo}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/_UBNdq6lX7s?autoplay=1&start=12&end=22&controls=0&loop=1&mute=1&playlist=_UBNdq6lX7s"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        <Arrow
          onClick={() =>
            document
              .getElementById("whatitis")
              ?.scrollIntoView({ behavior: "smooth" })
          }
          className={style.arrowDown}
        />
      </article>
      <article id="whatitis" className={style.whatitis}>
        <Reveal effect="slidein">
          <h1>C'est Quoi ?</h1>
          <p>
            La création artistique permet de valoriser le jeune, d'exposer sa
            différence avec fierté, de sublimer sa personalité en dessin et en
            couleurs. Cet atelier révèle l'artiste qui sommeille en lui dans la
            liberté de créer. Il lève les blocages, ouvre le champ des
            possibles, invite à voir autrement, ensemble.
          </p>
          <video controls={true} autoPlay={true} loop={true} muted={true}>
            <source src={whatitis} type="video/mp4" />
          </video>
        </Reveal>
      </article>
      <article className={style.cp}>
        <div className={style.cpTitle}>
          <Reveal effect="slidein">
            <h1>Atelier Classe de CP</h1>
            <dd>2017</dd>
          </Reveal>
        </div>
        <img src={cp1} alt="cp" className={style.cp1} />
        <img src={cp2} alt="cp" className={style.cp2} />
        <img src={cp3} alt="cp" className={style.cp3} />
        <div className={style.cp4}>
          <img src={cp4} alt="cp" />
        </div>
      </article>
      <article className={style.itep}>
        <div className={style.itepTitle}>
          <Reveal effect="slidein">
            <h1>Atelier ITEP</h1>
            <dd>2017</dd>
          </Reveal>
        </div>
        <img src={itep1} alt="itep" className={style.itep1} />
        <img src={itep2} alt="itep" className={style.itep2} />
        <div className={style.itep3}>
          <img src={itep3} alt="itep" />
        </div>
        <img src={itep4} alt="itep" className={style.itep4} />
      </article>
      <article className={style.segpa}>
        <div className={style.segpaTitle}>
          <Reveal effect="slidein">
            <h1>Atelier SEGPA</h1>
            <dd>2018</dd>
          </Reveal>
        </div>
        <img src={segpa1} alt="segpa" className={style.segpa1} />
        <img src={segpa2} alt="segpa" className={style.segpa2} />
        <img src={segpa3} alt="segpa" className={style.segpa3} />
        <div className={style.segpa4}>
          <img src={segpa4} alt="segpa" />
        </div>
      </article>
      <article className={style.mces}>
        <div className={style.mcesTitle}>
          <Reveal effect="slidein">
            <h1>Enfants Autistes/Enfants Placés en MECS</h1>
            <dd>2019</dd>
          </Reveal>
        </div>
        <img src={mces1} alt="mces" className={style.mces1} />
        <img src={mces2} alt="mces" className={style.mces2} />
        <div className={style.mces3}>
          <img src={mces3} alt="mces" />
        </div>
        <img src={mces4} alt="mces" className={style.mces4} />
      </article>
      <div className={style.whiteBlock}></div>
      <Partners className={style.partners} />
    </section>
  );
}
