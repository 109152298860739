import React from "react";
// @ts-ignore
import Reveal from "react-reveal/Reveal";
// @ts-ignore
import style from "./style.module.css";
export default function () {
  return (
    <section className={style.legal}>
      <article className={style.left}>
        <Reveal effect="slidein">
          <p>Lorem ipsum.</p>
        </Reveal>
      </article>
      <article className={style.right}>
        <Reveal effect="slidein">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit
            amet nibh sed ante convallis porta. Ut accumsan odio non neque
            cursus, et hendrerit neque aliquet. Proin ornare pellentesque
            aliquet. Suspendisse metus ligula, sollicitudin nec mollis et,
            vestibulum tincidunt orci. Cras porttitor interdum quam nec maximus.
            In nisl purus, scelerisque a mi at, sagittis commodo neque. Sed
            dapibus vel diam at accumsan. Integer eget faucibus lorem. Donec
            risus nulla, porta at posuere non, fermentum at purus. Cras ac
            pharetra velit. Vivamus eu diam ac metus mattis imperdiet. In nec
            libero vitae nulla feugiat malesuada. Vivamus eget nibh at sapien
            efficitur lobortis. Curabitur eu euismod quam. Cras dignissim augue
            in fermentum gravida. Pellentesque nec bibendum quam. Etiam arcu
            odio, pharetra a ante sed, hendrerit auctor nisi. Maecenas a tortor
            laoreet, ullamcorper dui eu, iaculis neque. Ut ornare felis est,
            vitae vestibulum arcu elementum sed. Proin cursus et diam vestibulum
            aliquam. Etiam nec porttitor lectus. Vivamus non suscipit elit, nec
            luctus nisi. Nullam ut consequat arcu. Vivamus placerat dapibus nisi
            in convallis. Nullam condimentum faucibus tortor, pretium mollis mi
            pulvinar convallis. Vivamus sed ipsum sem. Nunc bibendum, odio sed
            accumsan porta, magna felis eleifend libero, eu viverra elit elit a
            leo. Phasellus scelerisque consequat justo non finibus.
          </p>
        </Reveal>
      </article>
    </section>
  );
}
