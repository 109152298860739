import React from "react";
import Reveal from "react-reveal/Reveal";

import style from "./style.module.css";

export default function ({ hasshadow = false }: { hasshadow?: boolean }) {
  return (
    <article
      className={style.news.concat(hasshadow ? " " + style.hasshadow : "")}
    >
      <Reveal effect="slidein">
        <h3>Mon Actualité</h3>
        <dd>
          Envoyez-moi{" "}
          <a className={style.link} href="mailto:lacouleurlavie@orange.fr">
            un e-mail
          </a>{" "}
          pour vous abonner à ma newsletter et recevoir des informations
          exclusives ✨{" "}
        </dd>
      </Reveal>

      {[
        {
          title: "Réalisation",
          dd: "Portrait d'Alfred Sommier",
          description:
            "Hôtel de prestige Alfred Sommier, \n 20 Rue de l'Arcade, \n 75008 Paris",
        },
        {
          title: "Séminaire",
          dd: "Les Papillons Blancs de la Colline",
          description:
            "Le Collège de Corbeville, \n 12 Chem. du Collège,  \n 78790 Saint-Martin-des-Champs",
        },
        {
          title: "Numérologie",
          dd: "Jeu de cartes",
          description:
            "Un jeu de cartes en numérologie va bientôt voir le jour avec mes peintures en illustration",
        },
      ].map(({ title, dd, description }) => (
        <div key={title} className={style.exhibit}>
          <img
            className={style.horizontalarrow}
            src="/svg/arrow.svg"
            alt="arrow"
          />
          <Reveal effect="slidein">
            <h3>{title}</h3>
            <dd>{dd}</dd>
            <p>
              {description.split("\n").map((val, i) => (
                <span key={i}>{val}</span>
              ))}
            </p>
          </Reveal>
        </div>
      ))}
    </article>
  );
}
