import React from "react";
import Lottie from "lottie-react-web";
import animationData from "./arrow.json";
import animationDataEye from "./eye.json";
import style from "./style.module.css";

export const Eye = () => {
  return (
    <div className={style.eye}>
      <Lottie
        options={{
          animationData: animationDataEye,
          loop: false,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        speed={1}
      />
    </div>
  );
};

export const Arrow = ({
  className,
  onClick,
}: {
  onClick: () => void;
  className: string;
}) => {
  return (
    <div
      onClick={onClick}
      className={(className || "").concat(" ", style.arrow)}
    >
      <Lottie
        options={{
          animationData,
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        speed={1.5}
      />
    </div>
  );
};

export const ArrowUp = ({
  className,
  onClick,
}: {
  onClick: () => void;
  className: string;
}) => {
  return (
    <div
      onClick={onClick}
      className={(className || "").concat(" ", style.arrowUp)}
    >
      <Lottie
        options={{
          animationData,
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        speed={1.5}
      />
    </div>
  );
};
