import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Lottie from "lottie-react-web";
import animationData from "./burger_cross.json";

type HeaderbarType = {
  toggleMenu: () => void;
  isMenuVisible: boolean;
};

function titleFromPath(pathname: string) {
  const firstRoot = pathname.split("/")[1];
  switch (firstRoot) {
    case "galerie":
      return "Galerie";
    case "":
      return "Jeanne";
    case "painting":
      return "Gros plan";
    case "whoami":
      return "Qui suis-je ?";
    case "youth":
      return "Ateliers Jeunesse";
    case "workshop":
    case "teambuilding":
      return "Ateliers cohésion d'équipe";
    case "legal":
      return "Mentions Légales";
    default:
      return "??";
  }
}

function backFromPath(
  pathname: string,
  isMenuVisible: boolean,
  closeMenu: () => void
) {
  const firstRoot = pathname.split("/")[1];
  const secondRoot = pathname.split("/")[2];
  if (isMenuVisible) {
    return (
      <Link to="/" onClick={closeMenu}>
        Retour à la page d'accueil
      </Link>
    );
  }
  switch (firstRoot) {
    case "workshop":
      if (secondRoot && secondRoot.length > 0)
        return (
          <Link to="/teambuilding">
            Retour&nbsp;<span>à la description des ateliers</span>
          </Link>
        );
    case "painting":
      if (secondRoot && secondRoot.length > 0)
        return (
          <Link to="/galerie">
            Retour&nbsp;<span>à la Galerie</span>
          </Link>
        );
    case "legal":
    case "teambuilding":
    case "galerie":
    case "whoami":
    case "youth":
      return (
        <Link to="/">
          Retour&nbsp;<span>à la page d'accueil</span>
        </Link>
      );
    default:
      return "Peinture Contemporaine";
  }
}

export default function ({ toggleMenu, isMenuVisible }: HeaderbarType) {
  useLocation();
  const isHome = window.location.pathname.split("/")[1] === "";
  const [isPaused, setIsPaused] = useState(true);

  useEffect(() => {
    setIsPaused(false);
  }, [isMenuVisible]);
  return (
    <header
      className={style.header.concat(
        " ",
        isMenuVisible ? style.isMenuVisible : "",
        isHome ? style.isHome : ""
      )}
    >
      <p>
        {backFromPath(window.location.pathname, isMenuVisible, () => {
          toggleMenu();
        })}
      </p>
      <h1>{titleFromPath(window.location.pathname)}</h1>
      <button
        onClick={() => {
          toggleMenu();
        }}
        className={style.burger}
      >
        <span
          className={style.menuText.concat(
            " ",
            isMenuVisible ? style.hide : ""
          )}
        >
          Menu
        </span>
        {
          <div>
            <Lottie
              direction={isMenuVisible ? 1 : -1}
              options={{
                animationData,
                loop: false,
                autoplay: false,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height="5em"
              width="5em"
              speed={isMenuVisible ? 1.3 : 2}
              isPaused={isPaused}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: () => setIsPaused(true),
                },
              ]}
            />
          </div>
        }
      </button>
    </header>
  );
}
