import React from "react";
import style from "./style.module.css";
import Links from "./links";
import { ArrowUp } from "../shared";
import { Link } from "react-router-dom";

export default function () {
  return (
    <footer className={style.footer}>
      <span className={style.copyright}>
        <Link to="/legal">©</Link>
      </span>
      <Links></Links>
      <section className={style.madeby}>
        <p>
          Design and AD by{" "}
          <a href="https://dribbble.com/alexandregld">Alexandre Gellard</a>
        </p>
        <p>
          Built by <a href="https://madeinmoon.io">Made in Moon</a>
        </p>
      </section>
      <ArrowUp
        className={style.arrow}
        onClick={() =>
          document
            .getElementById("scrollable")
            ?.scrollTo({ top: 0, behavior: "smooth" })
        }
      />
    </footer>
  );
}
