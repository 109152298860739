import React from "react";
import style from "./style.module.css";
import paintings from "../galerie/paintings.json";
import { useParams } from "react-router-dom";
import calque from "./resources/calque.jpg";
import autoportrait from "./resources/autoportrait.png";
import seminaire from "./resources/seminaire.jpg";
import fresque from "./resources/fresque.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "../shared/Slider";

const cssModule = style;

export interface Spec {
  title: string;
  desc: string;
}

export interface Testimony {
  description: string;
  title: string;
  author: string;
}

export interface WorkshopType {
  painting: string;
  title: string;
  format: Spec;
  concept: Spec;
  idea: Spec;
  method: Spec;
  testimony: Testimony[];
}

const copy = {
  painting: calque,
  title: "Atelier Calque",
  format: {
    title: "Format",
    desc: "1 heure pour 5 à 15 personnes",
  },
  concept: {
    title: "Coût de la prestation",
    desc: "Tarif sur devis",
  },
  idea: {
    title: "Le concept",
    desc: "Mettre en lumière notre regard singulier, notre personalité, montrer notre valeur ajoutée, réveiller l'artiste qui est en nous.",
  },
  method: {
    title: "La méthode",
    desc: "Dans un climat de confiance et bienveillance, décalque d'une image identique proposée à tous les participants. Il s'agit de lâcher le mental pour retrouver sa créativité. Les dessins réalisés sur feuilles transparentes sont projetés en très grand sur un mur blanc pour créer l'étonnement du groupe et la valorisation personnelle.",
  },
  testimony: [
    {
      description:
        "Jeanne transmet le goût de la création et la découverte de son 'réservoir de créativité' en branchant chez chacun son envie et son plaisir de créer. Avec passion, douceur et pédagogie, Jeanne nous accompagne dans le développement de notre potentiel. Découvrir son style et s'ouvrir à la découverte de la richesse infinie de la différence : telle est l'exemple d'une découverte formidable.",
      title: "Fondatrice et dirigeante - TALENTIS",
      author: "Valérie Rocoplan",
    },
    {
      description:
        "J'ai toujours à coeur lors de mes réunions commerciales de proposer une activité à mon équipe qui leur rappelle que nous sommes un groupe constitué d'individualités à la fois différentes et complémentaires. L'atelier de Jeanne met parfaitement cela en exergue. Il permet à chacun de mesurer son importance, sa place et renforcer la cohésion de l'équipe.",
      title: "PDG - WYM FRANCE",
      author: "Stéphanie Grieu",
    },
    {
      description:
        "les consultants Vivacci ont adoré cette soirée à la redécouverte de la différence de leurs regards. L'animatrice était particulièrement agréable et accessible, elle a vraiment su mettre chacun très à l'aise et la soirée a permis au groupe un échange passionnant autour de ses envies, de ses motivations et de ses talents individuels.",
      title: "Consultante et coach en performance professionnelle - VIVACCI",
      author: "Florence Gazeau",
    },
  ],
};

const portrait = {
  painting: autoportrait,
  title: "Atelier Autoportrait",
  format: {
    title: "Format",
    desc: "2 heures pour 2 à 10 personnes",
  },
  concept: {
    title: "Coût de la prestation",
    desc: "Tarif sur devis",
  },
  idea: {
    title: "Le concept",
    desc: "Lever les blocages, se représenter simplement, sans attente et sans jugement, être créatif, révèler sa personalité et l'artiste qui vit en soi",
  },
  method: {
    title: "La méthode",
    desc: "Tous les participants sont pris en photo au préalable. Ils choisissent 4 couleurs qui leur parlent. Puis ils décalquent leur photo éditée sur papier et la reporte sur la toile. Elle est ensuite mise en couleur avec les teintes choisies",
  },
  testimony: [
    {
      description:
        "Jeanne transmet le goût de la création et la découverte de son 'réservoir de créativité' en branchant chez chacun son envie et son plaisir de créer. Avec passion, douceur et pédagogie, Jeanne nous accompagne dans le développement de notre potentiel. Découvrir son style et s'ouvrir à la découverte de la richesse infinie de la différence : telle est l'exemple d'une découverte formidable.",
      title: "Fondatrice et dirigeante - TALENTIS",
      author: "Valérie Rocoplan",
    },
    {
      description:
        "J'ai toujours à coeur lors de mes réunions commerciales de proposer une activité à mon équipe qui leur rappelle que nous sommes un groupe constitué d'individualités à la fois différentes et complémentaires. L'atelier de Jeanne met parfaitement cela en exergue. Il permet à chacun de mesurer son importance, sa place et renforcer la cohésion de l'équipe.",
      title: "PDG - WYM FRANCE",
      author: "Stéphanie Grieu",
    },
    {
      description:
        "les consultants Vivacci ont adoré cette soirée à la redécouverte de la différence de leurs regards. L'animatrice était particulièrement agréable et accessible, elle a vraiment su mettre chacun très à l'aise et la soirée a permis au groupe un échange passionnant autour de ses envies, de ses motivations et de ses talents individuels.",
      title: "Consultante et coach en performance professionnelle - VIVACCI",
      author: "Florence Gazeau",
    },
  ],
};

const tree = {
  painting: seminaire,
  title: "Atelier Arbre de vie",
  format: {
    title: "Format",
    desc: "2 heures pour 1 à 15 personnes",
  },
  concept: {
    title: "Coût de la prestation",
    desc: "Tarif sur devis",
  },
  idea: {
    title: "Le concept",
    desc: "À travers la symbolique de l'arbre, mise en lumière des besoins et des ressources. Cet atelier donne du sens au parcours personnel et professionnel. Il permet de fédèrer, de donner du sens et de la fluidité au travail d'équipe.",
  },
  method: {
    title: "La méthode",
    desc: "L'arbre est dessiné, avec racines, sol, tronc, branches, feuilles, fruits, puis mis en mots et en couleurs. Chaque partie de l'arbre représente symboliquement une information bien définie. Il est question de sens, de besoins, de compétences, de projets, de soutiens, de cadeaux de la vie. Il peut être pertinent de doubler cet atelier avec un éveil à la numérologie.",
  },
  testimony: [
    {
      description:
        "les consultants Vivacci ont adoré cette soirée à la redécouverte de la différence de leurs regards. L'animatrice était particulièrement agréable et accessible, elle a vraiment su mettre chacun très à l'aise et la soirée a permis au groupe un échange passionnant autour de ses envies, de ses motivations et de ses talents individuels.",
      title: "Consultante et coach en performance professionnelle - VIVACCI",
      author: "Florence Gazeau",
    },
    {
      description:
        "J'ai toujours à coeur lors de mes réunions commerciales de proposer une activité à mon équipe qui leur rappelle que nous sommes un groupe constitué d'individualités à la fois différentes et complémentaires. L'atelier de Jeanne met parfaitement cela en exergue. Il permet à chacun de mesurer son importance, sa place et renforcer la cohésion de l'équipe.",
      title: "PDG - WYM FRANCE",
      author: "Stéphanie Grieu",
    },
    {
      description:
        "Jeanne transmet le goût de la création et la découverte de son 'réservoir de créativité' en branchant chez chacun son envie et son plaisir de créer. Avec passion, douceur et pédagogie, Jeanne nous accompagne dans le développement de notre potentiel. Découvrir son style et s'ouvrir à la découverte de la richesse infinie de la différence : telle est l'exemple d'une découverte formidable.",
      title: "Fondatrice et dirigeante - TALENTIS",
      author: "Valérie Rocoplan",
    },
  ],
};

const fresco = {
  painting: fresque,
  title: "Atelier Fresque",
  format: {
    title: "Format",
    desc: "Une journée pour 6 à 12 personnes",
  },
  concept: {
    title: "Coût de la prestation",
    desc: "Tarif sur devis",
  },
  idea: {
    title: "Le concept",
    desc: "Solliciter imagination et coopération. Mettre en couleur la culture d’entreprise. Renforcer l’identité du groupe et la fierté d’appartenance. Mise en relief de la richesse de chacun au profit du groupe, y trouver l’harmonie.",
  },
  method: {
    title: "La méthode",
    desc: "Un grand choix d'images est proposé aux participants en fonction des valeurs de l'entreprise. Ils choisissent, décalquent, reproduisent leurs images sur une grande toile puis posent la couleur. La toile, surprenante et harmonieuse, restera dans l’entreprise comme le témoin d’un moment riche de joie et coopération.",
  },
  testimony: [
    {
      description:
        "J'ai toujours à coeur lors de mes réunions commerciales de proposer une activité à mon équipe qui leur rappelle que nous sommes un groupe constitué d'individualités à la fois différentes et complémentaires. L'atelier de Jeanne met parfaitement cela en exergue. Il permet à chacun de mesurer son importance, sa place et renforcer la cohésion de l'équipe.",
      title: "PDG - WYM FRANCE",
      author: "Stéphanie Grieu",
    },
    {
      description:
        "Jeanne transmet le goût de la création et la découverte de son 'réservoir de créativité' en branchant chez chacun son envie et son plaisir de créer. Avec passion, douceur et pédagogie, Jeanne nous accompagne dans le développement de notre potentiel. Découvrir son style et s'ouvrir à la découverte de la richesse infinie de la différence : telle est l'exemple d'une découverte formidable.",
      title: "Fondatrice et dirigeante - TALENTIS",
      author: "Valérie Rocoplan",
    },
    {
      description:
        "les consultants Vivacci ont adoré cette soirée à la redécouverte de la différence de leurs regards. L'animatrice était particulièrement agréable et accessible, elle a vraiment su mettre chacun très à l'aise et la soirée a permis au groupe un échange passionnant autour de ses envies, de ses motivations et de ses talents individuels.",
      title: "Consultante et coach en performance professionnelle - VIVACCI",
      author: "Florence Gazeau",
    },
  ],
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className
        .concat(" " + cssModule.arrow)
        .concat(" " + cssModule.arrowNext)}
      style={{
        ...style,
        background: "url(/svg/arrow.svg)",
        backgroundSize: "cover",
        transform: "Rotate(90deg)",
      }}
      onClick={onClick}
    />
  );
};
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className
        .concat(" " + cssModule.arrow)
        .concat(" " + cssModule.arrowPrev)}
      style={{
        ...style,
        background: "url(/svg/arrow.svg)",
        backgroundSize: "cover",
        transform: "Rotate(-90deg)",
      }}
      onClick={onClick}
    />
  );
};
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

function renderWorkshop(workshop: WorkshopType) {
  return [
    <div className={style.component}>
      <article className={style.workshop}>
        <img
          className={`${style.picture} ${style.pictureBig}`}
          src={workshop.painting}
          alt="workshop picture"
        />
        <div className={style.titlecontainer}>
          <h2 className={style.title}>{workshop.title}</h2>
        </div>
        <section className={style.details}>
          <img
            className={`${style.picture} ${style.pictureSmall}`}
            src={workshop.painting}
            alt="workshop picture"
          />
          <section className={style.format.concat(" " + style.info)}>
            <h6>{workshop.format.title}</h6>
            <dd>{workshop.format.desc}</dd>
          </section>
          <section className={style.concept.concat(" " + style.info)}>
            <h6>{workshop.concept.title}</h6>
            <dd>{workshop.concept.desc}</dd>
          </section>
          <section className={style.idea.concat(" " + style.info)}>
            <h6>{workshop.idea.title}</h6>
            <dd>{workshop.idea.desc}</dd>
          </section>
          <section className={style.method.concat(" " + style.info)}>
            <h6>{workshop.method.title}</h6>
            <dd>{workshop.method.desc}</dd>
          </section>
        </section>
      </article>
      <div className={style.background}></div>
    </div>,
    <div className={style.slider}>
      <Slider>
        {workshop.testimony.map(({ description, title, author }, i) => (
          <div key={i}>
            <div className={style.testimony}>
              <p>{description}</p>
              <h6>{title}</h6>
              <dd>{author}</dd>
            </div>
          </div>
        ))}
      </Slider>
    </div>,
  ];
}

export default function () {
  const { id } = useParams();
  let child;

  switch (id) {
    case "copy":
      child = renderWorkshop(copy);
      break;
    case "portrait":
      child = renderWorkshop(portrait);
      break;
    case "tree":
      child = renderWorkshop(tree);
      break;
    case "fresco":
      child = renderWorkshop(fresco);
      break;
  }
  return <React.Fragment>{child}</React.Fragment>;
}
