import React from "react";
import style from "./style.module.css";
export default function ({ className }: { className: string }) {
  return (
    <section className={style.links.concat(" ", className)}>
      <a href="mailto:lacouleurlavie@orange.fr">Écrivez moi</a>
      <a href="tel:+33680233479">Appelez moi</a>
      <a href="https://www.facebook.com/jeanne.artiste.peintre">Facebook</a>
      <a href="https://www.instagram.com/jeanneartistepeintre">Instagram</a>
    </section>
  );
}
