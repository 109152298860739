import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  ReactElement,
} from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Footer from "./footer";
import Galerie from "./galerie";
import Header from "./header";
import CloseUp from "./closeup";
import Menu from "./menu";
import Home from "./home";
import Whoami from "./whoami";
import Youth from "./youth";
import TeamBuilding from "./teambuilding";
import Workshop from "./workshop";
import Legal from "./legal";
import style from "./style.module.css";
import "./myreset.css";
import {
  AnimatePresence,
  motion,
  useElementScroll,
  useMotionValue,
} from "framer-motion";
import smoothscroll from "smoothscroll-polyfill";

import * as images from "../public/galerie/loader/*.jpg";

smoothscroll.polyfill();

function HandleScroll() {
  const { pathname } = useLocation();
  const [galerieTopScroll, setGalerieTopScroll] = useState(0);

  const trackScrollPos = useCallback(() => {
    const scrollable = document.getElementById("scrollable");
    setGalerieTopScroll(scrollable?.scrollTop || 0);
  }, [pathname]);

  useEffect(() => {
    const scrollable = document.getElementById("scrollable");
    if (pathname.indexOf("/galerie") === 0) {
      scrollable?.addEventListener("scroll", trackScrollPos);
      scrollable?.scrollTo({ top: galerieTopScroll });
    }
    return () => {
      scrollable?.removeEventListener("scroll", trackScrollPos);
    };
  }, [trackScrollPos]);

  useEffect(() => {
    if (pathname.indexOf("/galerie") === 0) return;
    const scrollable = document.getElementById("scrollable");
    if (scrollable) scrollable.scrollTo({ top: 0 });
  }, [pathname]);

  return null;
}

const ScrollView = ({ children }: { children: ReactElement }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useElementScroll(ref);

  const cloned = React.cloneElement(children, {
    ...children.props,
    scrollYProgress,
  });
  return (
    <div id="scrollable" ref={ref} className={style.page}>
      <main>{cloned}</main>
      <Footer />
    </div>
  );
};

const len = Object.values(images).length;
const imgs: string[] = (Object.values(images) as string[]).slice(0, len - 1);

const parentVariants = {
  removed: {
    y: "-100%",
    transition: {
      y: {
        type: "tween",
        duration: 0.5,
      },
    },
  },
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delay: 2,
      delayChildren: 2,
      staggerChildren: 0.2,
      type: "tween",
      duration: 1,
      opacity: {
        type: "tween",
        duration: 0.4,
      },
    },
  },
};

const childrenVariants = {
  hidden: {
    height: "0",
  },
  show: {
    height: "100%",
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
};

const loadFont = () =>
  fetch("../public/fonts/Calma-Medium/fonts/Calma-Medium.eot");

export default function App() {
  const [menu, setMenu] = useState<boolean>(false);
  const [isAnimatingGallerie, setIsAnimatingGallerie] =
    useState<boolean>(false);

  const [isFontLoaded, setIsFontLoaded] = useState<boolean>(false);
  const [isLandingTime, setIsLandingTime] = useState<boolean>(true);

  useEffect(() => {
    loadFont().then(() => {
      setIsFontLoaded(true);
      return Promise.all(imgs.map((src) => fetch(src))).then(() => {
        setIsAnimatingGallerie(true);
        setTimeout(() => setIsLandingTime(false), 2500);
      });
    });
  }, []);

  useEffect(() => {
    if (isAnimatingGallerie) {
      setTimeout(() => {
        setIsAnimatingGallerie(false);
      }, 5000);
    }
  }, [isAnimatingGallerie]);

  return (
    <Router>
      {isLandingTime && (
        <motion.div className={style.tagline}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={isFontLoaded ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={style.jeanne}
          >
            Jeanne
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={isFontLoaded ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={style.sentence}
          >
            L'œuvre d'art est un pont jeté entre deux âmes
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={isFontLoaded ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={style.loading}
          >
            Chargement...
          </motion.div>
        </motion.div>
      )}
      <AnimatePresence>
        {isAnimatingGallerie && (
          <motion.div
            style={{
              zIndex: 999,
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            variants={parentVariants}
            initial="hidden"
            animate="show"
            exit="removed"
          >
            {imgs.map((src: string, i) => (
              <motion.div
                variants={childrenVariants}
                key={src}
                style={{
                  bottom: 0,
                  overflow: "hidden",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              >
                <motion.img
                  style={{
                    position: "absolute",
                    width: "100vw",
                    height: "100vh",
                    objectFit: "cover",
                    objectPosition: "center",
                    bottom: 0,
                  }}
                  src={src}
                ></motion.img>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <HandleScroll />
      <Header
        toggleMenu={() => {
          setMenu(!menu);
        }}
        isMenuVisible={menu}
      ></Header>
      <Menu close={() => setMenu(false)} isVisible={menu}></Menu>
      {
        <Switch>
          <Route path="/workshop/:id">
            <ScrollView>
              <Workshop />
            </ScrollView>
          </Route>
          <Route path="/painting/:id">
            <div key="painting" id="scrollable" className={style.page}>
              <main>
                <CloseUp></CloseUp>
              </main>
              <Footer />
            </div>
          </Route>
          <Route path="/galerie">
            <div key="galerie" id="scrollable" className={style.page}>
              <main>
                <Galerie></Galerie>
              </main>
              <Footer />
            </div>
          </Route>
          <Route path="/whoami">
            <div key="whoami" id="scrollable" className={style.page}>
              <main>
                <Whoami></Whoami>
              </main>
              <Footer />
            </div>
          </Route>
          <Route path="/youth">
            <div key="youth" id="scrollable" className={style.page}>
              <main>
                <Youth></Youth>
              </main>
              <Footer />
            </div>
          </Route>
          <Route path="/teambuilding">
            <div key="teambuilding" id="scrollable" className={style.page}>
              <main>
                <TeamBuilding></TeamBuilding>
              </main>
              <Footer />
            </div>
          </Route>
          <Route path="/legal">
            <div key="legal" id="scrollable" className={style.page}>
              <main>
                <Legal />
              </main>
              <Footer />
            </div>
          </Route>
          <Route path="/">
            <ScrollView>
              <Home />
            </ScrollView>
          </Route>
        </Switch>
      }
    </Router>
  );
}

document.addEventListener("DOMContentLoaded", function (event) {
  ReactDOM.render(<App />, document.getElementById("lacouleurlavie"));
});
