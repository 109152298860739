import React, { useCallback, useMemo, useState } from "react";
import style from "./style.module.css";
import arrow from "./arrow.svg";
import Links from "../footer/links";
import img1 from "./assets/1.jpg";
import img2 from "./assets/2.jpg";
import img3 from "./assets/3.jpg";
import img4 from "./assets/4.jpg";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router-dom";

type HeaderbarType = {
  isVisible: boolean;
  close: () => void;
};

const AnimatedImg = ({
  selectedImage,
  zIndex,
}: {
  selectedImage: string;
  zIndex: number;
}) => (
  <motion.img
    style={{ position: "absolute", zIndex }}
    initial={{ x: 20, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ opacity: 0, x: -20 }}
    transition={{
      x: { duration: 0.5 },
      opacity: { duration: 0.5 },
    }}
    src={selectedImage}
    alt="photographie du travail de Jeanne"
  />
);

const images = [img1, img2, img3, img4];

const variants = {
  open: {
    opacity: 1,
    transition: { staggerChildren: 0.1, delayChildren: 0.3 },
  },
  closed: {
    opacity: 0,
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    opacity: 0,
    y: 50,
  },
};

export default function ({ isVisible, close }: HeaderbarType) {
  const [selectedImage, setSelectedImage] = useState(0);
  const history = useHistory();

  return (
    <nav className={style.nav.concat(" " + (isVisible ? style.visible : ""))}>
      <motion.section
        variants={variants}
        animate={isVisible ? "open" : "closed"}
        className={style.links}
      >
        <motion.div
          variants={itemVariants}
          onMouseEnter={() => setSelectedImage(0)}
          className={style.link}
        >
          <p>01.</p>
          <a
            onClick={() => {
              history.push("/galerie");
              close();
            }}
          >
            <img src={arrow} className={style.arrow}></img>Galerie
          </a>
        </motion.div>
        <motion.div
          variants={itemVariants}
          onMouseEnter={() => setSelectedImage(1)}
          className={style.link}
        >
          <p>02.</p>
          <a
            onClick={() => {
              history.push("/youth");
              close();
            }}
          >
            <img src={arrow} className={style.arrow}></img>Ateliers jeunesse
          </a>
        </motion.div>
        <motion.div
          variants={itemVariants}
          onMouseEnter={() => setSelectedImage(2)}
          className={style.link}
        >
          <p>03.</p>
          <a
            onClick={() => {
              history.push("/teambuilding");
              close();
            }}
          >
            <img src={arrow} className={style.arrow}></img>Ateliers cohésion
            d'équipe
          </a>
        </motion.div>
        <motion.div
          variants={itemVariants}
          onMouseEnter={() => setSelectedImage(3)}
          className={style.link}
        >
          <p>04.</p>
          <a
            onClick={() => {
              history.push("/whoami");
              close();
            }}
          >
            <img src={arrow} className={style.arrow}></img>Qui suis-je ?
          </a>
        </motion.div>
      </motion.section>
      <section className={style.illustration}>
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <AnimatePresence>
            {
              <AnimatedImg
                key={selectedImage}
                zIndex={selectedImage}
                selectedImage={images[selectedImage]}
              />
            }
          </AnimatePresence>
        </div>
      </section>
      <section className={style.info}>
        <p>Plus d'informations</p>
        <Links className={style.bottomLinks}></Links>
      </section>
    </nav>
  );
}
