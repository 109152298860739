import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Arrow } from "./index";
import style from "./slider.module.css";

const SampleNextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div
      className={className
        .concat(" " + style.arrow)
        .concat(" " + style.arrowNext)}
      style={{
        background: "url(/svg/arrow.svg)",
        backgroundSize: "cover",
        transform: "Rotate(90deg)",
      }}
      onClick={onClick}
    />
  );
};
const SamplePrevArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div
      className={className
        .concat(" " + style.arrow)
        .concat(" " + style.arrowPrev)}
      style={{
        background: "url(/svg/arrow.svg)",
        backgroundSize: "cover",
        transform: "Rotate(-90deg)",
      }}
      onClick={onClick}
    />
  );
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const dotVariants = {
  default: { fontSize: "1em", backgroundColor: "var(--grey)" },
  active: { fontSize: "1.2em", backgroundColor: "#000" },
};

export default ({ children }: { children: React.ReactElement[] }) => {
  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const index = Math.abs(page % children.length);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <div className={style.root}>
      <div className={style.slider}>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className={style.slide}
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: {
                type: "spring",
                stiffness: 125,
                damping: 25,
              },
              opacity: {
                duration: 0.1,
              },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(_e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            {children[index]}
          </motion.div>
        </AnimatePresence>
        <SampleNextArrow className={style.next} onClick={() => paginate(1)} />
        <div className={style.dots}>
          {children.map((_, i) => (
            <motion.div
              animate={i === index ? "active" : "default"}
              variants={dotVariants}
              key={i}
              className={style.dot.concat(
                i === index ? ` ${style.selected}` : ""
              )}
            ></motion.div>
          ))}
        </div>
        <SamplePrevArrow className={style.prev} onClick={() => paginate(-1)} />
      </div>
    </div>
  );
};
