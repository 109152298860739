import React from "react";
import Reveal from "react-reveal/Reveal";
import style from "./style.module.css";
import Links from "../footer/links";
import herogalerie from "./resources/Hero galerie.png";
import aboutcanvas from "./resources/About_Canvas.png";
import youth from "./resources/AtelierJeunesse_Canvas.png";
import teambuilding from "./resources/AtelierTeamBuilding_Canvas.png";
import { Link } from "react-router-dom";
import ScrollableGalerie from "../galerie/scrollable";
import News from "./news";
import { MotionValue, useTransform } from "framer-motion";
import { Arrow } from "../shared/index";

export default function ({
  scrollYProgress,
}: {
  scrollYProgress?: MotionValue<number>;
}) {
  const xPercent = useTransform(
    scrollYProgress || new MotionValue(0),
    (v) => -600 + v * 1000
  );
  return (
    <section className={style.home}>
      <article className={style.sens}>
        <Reveal effect="slidein">
          <h3>“Donne un sens à ta vie et de la vie à tes sens”</h3>
          <p>
            Peinture Comtemporaine
            <br />
            Depuis 1996
          </p>
        </Reveal>

        <Links className={style.links} />
        <img
          className={style.herogalerie}
          src={herogalerie}
          alt="herogalerie"
        />
        <Arrow
          onClick={() =>
            document
              .getElementById("jeanne")
              ?.scrollIntoView({ behavior: "smooth" })
          }
          className={style.arrow}
        />
      </article>
      <article id="jeanne" className={style.jeanne}>
        <img
          className={style.aboutcanvas}
          src={aboutcanvas}
          alt="aboutcanvas"
        />
        <div className={style.description}>
          <Reveal effect="slidein">
            <h3>jeanne</h3>
            <dd>Qui suis-je ?</dd>
            <p>
              Force et Beauté existe en chacun de nous
              <br />
              La puissance d'une rencontre peut ouvrir sur le monde infini de la
              créativité
            </p>
            <Link className={style.link} to="/whoami">
              En Savoir Plus
            </Link>
          </Reveal>
        </div>
      </article>
      <article className={style.galerie}>
        <ScrollableGalerie
          scrollPercentage={xPercent}
          className={style.scrollableGalerie}
        />
        <Link className={style.link} to="/galerie">
          <div className={style.showall}>
            <div>
              Voir toute la galerie en ligne · Voir toute la galerie en ligne ·
              Voir toute la galerie en ligne · Voir toute la galerie en ligne ·
              Voir toute la galerie en ligne ·{" "}
            </div>
            <div>
              Voir toute la galerie en ligne · Voir toute la galerie en ligne ·
              Voir toute la galerie en ligne · Voir toute la galerie en ligne ·
              Voir toute la galerie en ligne ·{" "}
            </div>
          </div>
        </Link>
      </article>
      <article className={style.youth}>
        <img className={style.youthworkshop} src={youth} alt="youth" />
        <div className={style.youthDescription}>
          <Reveal effect="slidein">
            <h3>Atelier Jeunesse</h3>
            <dd>L'éveil des sens</dd>
            <p>
              Cet atelier, facile et ludique, est un moyen concret de révéler la
              singularité de l'artiste qui sommeille en chacun, de faire émerger
              l'intelligence émotionnelle
            </p>
            <Link className={style.link} to="/youth">
              En Savoir Plus
            </Link>
          </Reveal>
        </div>
      </article>
      <Reveal effect="slidein">
        <News></News>
      </Reveal>

      <article className={style.teambuilding}>
        <img
          className={style.teambuildingpic}
          src={teambuilding}
          alt="teambuilding"
        />
        <div className={style.description}>
          <Reveal effect="slidein">
            <h3>Ateliers cohésion d'équipe</h3>
            <dd>Ateliers innovants</dd>
            <p>
              Ces ateliers sont des moyens concrets et réels de toucher du doigt
              la puissance de la créativité, de faire émerger l’intelligence
              collective si recherchée aujourd’hui dans vos métiers.
            </p>
            <Link className={style.link} to="/teambuilding">
              En Savoir Plus
            </Link>
          </Reveal>
        </div>
      </article>
    </section>
  );
}
