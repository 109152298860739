import React from "react";
import Reveal from "react-reveal/Reveal";
import style from "./style.module.css";
import Links from "../footer/links";
import { Link } from "react-router-dom";
import jeanne from "./resources/Photo Jeanne.png";
import work from "./resources/Workshop_pics.png";
import journey from "./resources/journey.png";
import couleur from "./resources/Une envie de couleur - pics.png";
import News from "../home/news";
import { Arrow } from "../shared";
export default function () {
  return (
    <section className={style.whoami}>
      <article className={style.intro}>
        <Reveal effect="slidein">
          <p>Une histoire de résilience…</p>
          <h1>
            La force qui existe en chacun de nous. Un chagrin suivi d’une
            rencontre peut ouvrir sur un monde infini.
          </h1>
        </Reveal>
        <img className={style.jeanne} src={jeanne} alt="jeanne" />
        <Arrow
          onClick={() =>
            document
              .getElementById("description")
              ?.scrollIntoView({ behavior: "smooth" })
          }
          className={style.arrow}
        />
      </article>
      <article id="description" className={style.description}>
        <Reveal effect="slidein">
          <h6>Qui suis-je ?</h6>
          <p>
            Je vis et travaille à la Baule. J'ai 3 fils ; Alexandre,
            Jean-Baptiste, Augustin, mon bonheur, ma fierté ! ✨<br />
            J’ai commencé à peindre il y a plus de vingt ans suite à la
            rencontre de Jean Bouron, à Nantes, qui m’a ouvert son atelier et
            m’a révélé mon besoin de créer. 🎨
            <br /> Mes œuvres sont un moyen de me connecter au regard de l’autre
            à travers sa sensibilité et son intimité. J’utilise la puissance de
            la couleur avec gourmandise pour faire écho à nos vibrations
            intérieures.
          </p>
        </Reveal>
      </article>
      <article className={style.work}>
        <img className={style.workpic} src={work} alt="jeanne" />
        <Reveal effect="slidein">
          <h3>Mon Travail</h3>
          <p>
            Ma peinture, figurative, est principalement liée au portrait.
            J'interprète l'image sur la toile et la fixe dans une forme
            d'intemporalité grâce à la résine que j'applique en couche de
            finition. Cette résine me plaît par son aspect gourmand qui invite à
            la sensualité du toucher et sa texture brillante qui intensifie la
            vibration des couleurs. Elle est mon amie précieuse dans ma
            recherche d'équilibre entre le charnel et le spirituel, le monde
            visible et invisible. Je cherche à capter le mystère la vérité d'une pure
            émotion.
          </p>
        </Reveal>
      </article>

      <article className={style.journey}>
        <img className={style.journeypic} src={journey} alt="jeanne" />
        <div className={style.journeycontent}>
          <Reveal effect="slidein">
            <h3>Mon Parcours</h3>
          </Reveal>
          <div>
            <Reveal effect="slidein">
              <span>1996</span>
              <span>Ecole de Jean Bouron à Nantes</span>
            </Reveal>
            <Reveal effect="slidein">
              <span>1996 - 2003</span>
              <span>
                Biennale de l'Art Nantes - Salon Art contemporain Versailles -
                Galerie Le Temple du Goût Nantes, A.Galerie Nantes, Galerie Le
                Rayon Vert Nantes
              </span>
            </Reveal>
            <Reveal effect="slidein">
              <span>2004 - 2006</span>
              <span>ECOLE DES BEAUX ARTS de Nantes</span>
            </Reveal>
            <Reveal effect="slidein">
              <span>2006 - 2007</span>
              <span>
                Galerie La Découverte Nantes, La Chapelle Sainte Anne La Baule,
                Galerie Mondapart Paris, Galerie du XXVIème Paris, Hôtel Arthus
                Paris
              </span>
            </Reveal>
            <Reveal effect="slidein">
              <span>2008</span>
              <span>
                OPERA GALLERY Paris, ROLLEBEEK GALERIE Bruxelles, Salon ART
                SHOPPING Carrousel du Louvre Paris, toiles pour la STAR ACADEMY
                Paris
              </span>
            </Reveal>
            <Reveal effect="slidein">
              <span>2009</span>
              <span>
                Galerie RAUCHFELD Paris, MONGEMERY FINE ART Carmel (USA)
              </span>
            </Reveal>
            <Reveal effect="slidein">
              <span>2010</span>
              <span>Galerie ARTEUM Paris</span>
            </Reveal>
            <Reveal effect="slidein">
              <span>2011</span>
              <span>Galerie MARCIANO Paris, Musée MARMOTTAN Boulogne</span>
            </Reveal>
            <Reveal effect="slidein">
              <span>2012 - 2013</span>
              <span>
                Galerie Pour toi l'Artiste Valenciennes, Galerie Moineau Nantes,
                Expositions privées Annecy, Paris, Galerie Rollebeek Bruxelles
              </span>
            </Reveal>
            <Reveal effect="slidein">
              <span>2014 - 2017</span>
              <span>
                SALON D'ART CONTENPORAIN ART3f et ART Shopping Nantes, Paris,
                Lyon, Bruxelles, Mulhouse, Toulouse, Metz
              </span>
            </Reveal>
            <Reveal effect="slidein">
              <span>2018-2021</span>
              <span>
                Galerie Jeanne La Baule, Galerie Marciano place des Vosges
              </span>
            </Reveal>
          </div>
        </div>
      </article>

      <News hasshadow></News>
      <article className={style.blank}></article>
      <article className={style.color}>
        <div className={style.imgWrapper}>
          <img className={style.colorpic} src={couleur} alt="couleur" />
        </div>
        <Reveal effect="slidein">
          <h3>Une envie de couleur ?</h3>
          <p>
            Je vous propose de réaliser un portrait ou un tableau de famille en
            transposant sur la toile votre propre photo ou celle realisée
            ensemble à l'atelier. 
          </p>
        </Reveal>
        <article className={style.contact}>
          <Reveal effect="slidein">
            <article className={style.email}>
              <h6>E-mail</h6>
              <a href="mailto:lacouleurlavie@orange.fr">
                lacouleurlavie@orange.fr
              </a>
            </article>
            <article className={style.network}>
              <h6>Social</h6>
              <a href="https://www.facebook.com/jeanne.artiste.peintre">
                Facebook
              </a>
              <a href="https://www.instagram.com/jeanneartistepeintre">
                Instagram
              </a>
            </article>
            <article className={style.phone}>
              <h6>Téléphone</h6>
              <a href="tel:+33680233479">06 68 23 34 79</a>
            </article>
          </Reveal>
        </article>
      </article>
    </section>
  );
}
