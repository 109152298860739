import React from "react";
import Reveal from "react-reveal/Reveal";
import style from "./style.module.css";
import paintings from "../galerie/paintings.json";
import { useParams } from "react-router-dom";

export default function () {
  const { id } = useParams();
  const painting = paintings[Number(id)];
  return (
    <div className={style.component}>
      <article className={style.closeup}>
        <div className={style.titlecontainer}>
          <Reveal effect="slidein">
            <h2 className={style.title}>{painting.title}</h2>
            <p className={style.year}>{painting.year}</p>
          </Reveal>
        </div>

        <section className={style.details}>
          <img
            className={style.picture}
            src={painting.source}
            alt={painting.title}
          />
          <Reveal effect="slidein">
            <section className={style.technique.concat(" " + style.info)}>
              <h6>Technique</h6>
              <dd>
                {painting.technique
                  .split("\n")
                  .map((str: string) => [str, <br />])
                  .flat()}
              </dd>
            </section>
            <section className={style.description.concat(" " + style.info)}>
              <h6>Tonalité</h6>
              <dd>
                {painting.description
                  .split("\n")
                  .map((str: string) => [str, <br />])
                  .flat()}
              </dd>
            </section>
            <section className={style.dimension.concat(" " + style.info)}>
              <h6>Dimensions</h6>
              <dd>{painting.dimension}</dd>
            </section>
            <section className={style.available.concat(" " + style.info)}>
              <h6>Statut</h6>
              <dd>{painting.available ? "Disponible" : "Vendu"}</dd>
            </section>
            <section className={style.price.concat(" " + style.info)}>
              <h6>Valeur</h6>
              <dd>{painting.price}</dd>
            </section>
          </Reveal>
        </section>
      </article>
      <div className={style.background}></div>
    </div>
  );
}
