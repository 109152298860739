import React from "react";
import Reveal from "react-reveal/Reveal";

import style from "./style.module.css";
import Links from "../footer/links";
import { Link } from "react-router-dom";
import p1 from "./resources/oiseau.jpg";
import p2 from "./resources/dessin.jpg";
import misenecouleur from "./resources/atelier mise en couleur.jpg";
import Partners from "../partners";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "../shared/Slider";
import { Arrow } from "../shared";

const testimonies = [
  {
    description:
      "Jeanne transmet le goût de la création et la découverte de son 'réservoir de créativité' en branchant chez chacun son envie et son plaisir de créer. Avec passion, douceur et pédagogie, Jeanne nous accompagne dans le développement de notre potentiel.",
    title: "Fondatrice et Dirigeante - TALENTIS",
    author: "Valérie Rocoplan",
  },
  {
    description:
      "les consultants Vivacci ont adoré cette soirée à la redécouverte de la différence de leurs regards. L'animatrice était particulièrement agréable et accessible, elle a vraiment su mettre chacun très à l'aise et la soirée a permis au groupe un échange passionnant autour de ses envies, de ses motivations et de ses talents individuels.",
    title: "Consultante et coach en performance professionnelle - VIVACCI",
    author: "Florence Gazeau",
  },
  {
    description:
      "J'ai toujours à coeur lors de mes réunions commerciales de proposer une activité à mon équipe qui leur rappelle que nous sommes un groupe constitué d'individualités à la fois différentes et complémentaires. L'atelier de Jeanne permet à chacun de mesurer son importance, sa place et renforcer la cohésion de l'équipe.",
    title: "PDG - WYM FRANCE",
    author: "Stéphanie Grieu",
  },
];

const WorkShop = (params: {
  title: string;
  description: string;
  link: string;
}) => (
  <div className={style.workshopLink}>
    <h3>{params.title}</h3>
    <dd>{params.description}</dd>
    <Link to={params.link}>En savoir plus</Link>
  </div>
);

const workshops = [
  {
    title: "Autoportrait",
    description:
      "Lever les blocages, oser se représenter, en jouer et bien le vivre, révéler sa personnalité",
    link: "/workshop/portrait",
  },
  {
    title: "Calque",
    description:
      "Atelier fondé sur le décalque d’une même image proposée à tous les participants",
    link: "/workshop/copy",
  },
  {
    title: "Arbre de Vie",
    description:
      " Avec la symbolique de l'arbre, mettre en mots et en couleurs notre parcours professionnel",
    link: "/workshop/tree",
  },
  {
    title: "Fresque",
    description: "Réaliser une œuvre collective qui restera dans l’entreprise",
    link: "/workshop/fresco",
  },
];

export default function () {
  return (
    <section className={style.teambuilding}>
      <article className={style.intro}>
        <Reveal effect="slidein">
          <p>Les Ateliers cohésion d'équipe</p>
          <h1>Valorisation personnelle au profit du groupe</h1>
        </Reveal>

        <img src={misenecouleur} alt="misenecouleur" />
        <Arrow
          onClick={() =>
            document
              .getElementById("touch")
              ?.scrollIntoView({ behavior: "smooth" })
          }
          className={style.arrowDown}
        />
      </article>
      <article id="touch" className={style.touch}>
        <img src={p1} alt="man and bird painting" />
        <Reveal effect="slidein">
          <h1>Toucher du doigt la puissance de la créativité</h1>
          <h6>Pourquoi ces ateliers</h6>
          <p>
            L’activité artistique, comme outil de communication, constitue un
            moyen efficace et ludique d’animer, de fédérer ou consolider une
            équipe. Elle met en exergue des valeurs personnelles et la culture
            d’entreprise. Elle permet de donner toute sa place à l’expression
            non verbale pour évacuer le stress et se découvrir autrement. Pour
            animer ces ateliers je me suis formée en PNL, Hypnose, Approche
            Narrative, Numérologie
          </p>
          <h6>L’objectif</h6>
          <p className={style.goals}>
            • Vivre une expérience positive, ludique et conviviale
            <br />
            • Révéler l’artiste qui est en soi
            <br />
            • Valoriser chacun par son unicité
            <br />
            • Créer du lien, renforcer l’écoute et la cohésion d’équipe
            <br />• Mise en évidence de la force créative du groupe
          </p>
        </Reveal>
      </article>
      <article className={style.workshop}>
        <Reveal effect="slidein">
          <h1>Les Quatres Ateliers Proposés</h1>
          <dd>Un atelier personnalisé peut être étudié sur demande</dd>
        </Reveal>

        <div className={style.workshopsContainer}>
          <Reveal effect="slidein">
            {workshops.map((params, i) => (
              <WorkShop key={i} {...params}></WorkShop>
            ))}
          </Reveal>
        </div>
        <img src={p2} alt="drawing flower" />
        <div className={style.whiteBlock}></div>
      </article>
      <Partners className={style.partners} />
      <div className={style.slider}>
        <Slider>
          {testimonies.map(({ description, title, author }, i) => (
            <div key={i}>
              <div className={style.testimony}>
                <p>{description}</p>
                <h6>{title}</h6>
                <dd>{author}</dd>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
