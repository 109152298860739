import React from "react";
import Reveal from "react-reveal/Reveal";

import style from "./style.module.css";

import AeroportParis from "./resources/AeroportParis.png";
import ManutantCollectivites from "./resources/ManutantCollectivites.png";
import FM from "./resources/Fleury Michon.png";
import Papillons from "./resources/Les Papillons Blancs de la Colline.png";
import Mucovisidose from "./resources/Mucovisidose.png";
import Plufor from "./resources/Plufor.png";
import Talentis from "./resources/Talentis.png";
import Vivacci from "./resources/Vivacci.png";
import Fouque from "./resources/AssociationFouque.png";
import Externat from "./resources/Externat.png";
import JeunesseAvenir from "./resources/JeunesseAvenir.png";

const youthPartners = [
  {
    src: Externat,
    title: "Externat Des Enfants Nantais",
    desc: "Collège SEGPA Nantes",
  },
  { src: JeunesseAvenir, title: "Jeunesse & Avenir", desc: "ITEP" },
  {
    src: Fouque,
    title: "Association Fouque - Marseille",
    desc: "interventions pour jeunes autistes et enfants placés en MECS",
  },
  {
    src: Papillons,
    title: "Les Papillons Blancs de la Colline",
    desc: "Intervention pour les enfants autistes (pas classe CP)",
  },
];

const teamPartners = [
  { src: AeroportParis, title: "Aéroport de Paris", desc: "Aéroport" },
  { src: Mucovisidose, title: "Vaincre la Mucoviscidose", desc: "Association" },
  {
    src: Papillons,
    title: "Les Papillons Blancs de la Colline",
    desc: "Association",
  },
  {
    src: Vivacci,
    title: "Vivacci",
    desc: "Conseiller en Gestion des Affaires",
  },
  { src: Talentis, title: "Talentis", desc: "Coaching de Dirigeants" },
  { src: Plufor, title: "Plufor", desc: "Coaching Conseil" },
  {
    src: FM,
    title: "Fleury Michon",
    desc: "Entreprise de l'industrie agroalimentaire française",
  },
  {
    src: ManutantCollectivites,
    title: "Manutan Collectivités",
    desc: "expert en aménagement d'espaces",
  },
];

type ParnerType = {
  title: string;
  desc: string;
  src: string;
  id: number;
};

const Partner = ({ title, desc, id, src }: ParnerType) => (
  <article className={style.partner}>
    <div>
      <h6>{title}</h6>
      <dd>{desc}</dd>
      <img src={src} alt={`partner ${id}`} />
    </div>
  </article>
);

export default function ({ className = "" }) {
  return (
    <article className={className.concat(" ", style.partners)}>
      <Reveal effect="slidein">
        <h1>Partenaires Satisfaits</h1>
      </Reveal>

      <div>
        {window.location.pathname === "/youth"
          ? youthPartners.map((props, id) => (
              <Partner key={id} {...props} id={id}></Partner>
            ))
          : teamPartners.map((props, id) => (
              <Partner key={id} {...props} id={id}></Partner>
            ))}
      </div>
    </article>
  );
}
